import React, { useState, useEffect } from 'react';
import MainRecPage from './mainrecpage';
import MainPage from './mainpage';
import FilterPage from './filterpage';
import FrontPage from './frontpage';
import About from './about';
import { AppBar, Toolbar, Button, Box } from '@mui/material';

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filename, setFilename] = useState(null);

  useEffect(() => {
    // Handle browser back/forward button navigation
    window.onpopstate = (event) => {
      const page = event.state ? event.state.page : 'home';
      setCurrentPage(page);
      if (page === 'mainrec' && event.state && event.state.filename) {
        setFilename(event.state.filename);
      }
      if (page === 'mainpage' && event.state && event.state.categories) {
        setSelectedCategories(event.state.categories);
      }
    };
  }, []);

  const navigateToPage = (page, filename = null, categories = null) => {
    // Update the selected categories and current page
    if (categories !== null) {
      setSelectedCategories(categories);
    }
    setCurrentPage(page);
    setFilename(filename);
    updateURL(page, filename, categories);
  };

  const handleHomeClick = () => {
    // Navigate to the home page without resetting selected categories
    navigateToPage('home');
  };

  const handleMindMapClick = () => {
      navigateToPage('mainpage', null, selectedCategories);
  };

  const updateURL = (page, filename, categories) => {
    // Update the browser's URL and history state
    const path = filename ? `/${page}/${filename}` : `/${page}`;
    window.history.pushState({ page, filename, categories }, '', path);
  };

  return (
    <div className="App">
      <AppBar position="fixed" className="NavL"> {/* Changed to 'fixed' */}
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <NavigationLinks
              currentPage={currentPage}
              handleHomeClick={handleHomeClick}
              handleMindMapClick={handleMindMapClick}
              navigateToPage={navigateToPage}
              selectedCategories={selectedCategories}
            />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Ensure there's enough space below the AppBar */}
      <div style={{ marginTop: '64px' }}> {/* Added margin to main content */}
        {currentPage === 'home' && (
          <FrontPage
            selectedCategories={selectedCategories}  // Pass selectedCategories here
            setSelectedCategories={setSelectedCategories}
            navigateToPage={navigateToPage}
          />
        )}
        {currentPage === 'mainpage' && (
          <div style={{ display: 'flex' }}>
            <aside>
              <FilterPage
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </aside>
            <MainPage
              selectedCategories={selectedCategories}
              navigateToPage={navigateToPage}
            />
          </div>
        )}
        {currentPage === 'mainrec' && <MainRecPage filename={filename} />}
        {currentPage === 'about' && <About />}
      </div>
    </div>
  );
}

function NavigationLinks({
  handleHomeClick,
  currentPage,
  handleMindMapClick,
  navigateToPage,
  selectedCategories,
}) {
  return (
    <>
      <Button
        color="inherit"
        onClick={handleHomeClick}
        style={{ fontWeight: currentPage === 'home' ? 'bold' : 'normal' }}
      >
        Home
      </Button>
      <Button
        color="inherit"
        onClick={handleMindMapClick}
        style={{ fontWeight: currentPage === 'mainpage' ? 'bold' : 'normal' }}
      >
        Mind Map
      </Button>
      <Button
        color="inherit"
        onClick={() => navigateToPage('about')}
        style={{ fontWeight: currentPage === 'about' ? 'bold' : 'normal' }}
      >
        About
      </Button>
    </>
  );
}

export default App;
