import React, { useState, useEffect, useRef } from 'react';

function MainRecPage({ filename }) {
  const [transcript, setTranscript] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const audioElementRef = useRef(null);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [category, setCategory] = useState('');
  const [showTranscript, setShowTranscript] = useState(false); // State to toggle transcript visibility

  // Fetch image, transcript, and title data from the server based on the filename when it changes.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/images/${encodeURIComponent(filename)}`);
        if (response.ok) {
          const category = response.headers.get('Category'); // Extract category from response headers
          setCategory(category);
          const imageUrl = URL.createObjectURL(await response.blob());
          setImageUrl(imageUrl);
        } else {
          console.error('Error fetching image:', response.statusText);
        }

        const stmResponse = await fetch(`/get-stm?filename=${filename}`);
        if (stmResponse.ok) {
          const data = await stmResponse.text();
          const cleanedTranscript = data.replace(/"/g, '');
          setTranscript(cleanedTranscript);
        } else {
          console.error('Error fetching transcript:', stmResponse.statusText);
        }

        const titleResponse = await fetch(`/get-title?filename=${filename}`);
        if (titleResponse.ok) {
          const data = await titleResponse.text();
          const [extractedAuthor, extractedTitle] = data.split(':').map(str => str.trim()); // Split title and author
          setAuthor(extractedAuthor);
          setTitle(extractedTitle);
        } else {
          console.error('Error fetching title:', titleResponse.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [filename]);

  // Update the audio element's source to fetch the corresponding audio file based on the filename.
  useEffect(() => {
    if (audioElementRef.current) {
      audioElementRef.current.src = `/get-mp3?filename=${filename}`;
    }
  }, [filename]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '75vh', marginTop: '8vh', alignItems: 'center' }}>
      <div style={{ textAlign: 'center', position: 'relative', maxWidth: '60%', width: '100%' }}>
        {/* Title shown above the image */}
        <h2 style={{ marginBottom: '10px' }}>{title}</h2>

        {/* Container to hold image and audio player with the same width */}
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {imageUrl && <img src={imageUrl} alt="Recording" style={{ maxWidth: '60%', height: 'auto', maxHeight: '370px', display: 'inline-block' }} />}

          {/* Fixed height audio player */}
          <audio ref={audioElementRef} controls style={{ width: '60%', height: '60px', marginTop: '20px' }}>
            <source type="audio/mpeg" />
          </audio>
        </div>

        {/* Author and category displayed below the audio on one line */}
        <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
          {author && <span>Author: {author}</span>} {' | '}
          {category && <span>Category: {category}</span>}
        </div>

        {/* Arrow to toggle transcript */}
        <div
          onClick={() => setShowTranscript(!showTranscript)}
          style={{ cursor: 'pointer', marginTop: '10px', fontWeight: 'bold', color: 'blue' }}
        >
          {showTranscript ? 'Hide Transcript ▲' : 'Show Transcript ▼'}
        </div>

        {/* Fixed height for transcript section */}
        <div style={{ marginTop: '10px', transition: 'max-height 0.3s ease', overflow: 'hidden', maxHeight: showTranscript ? '150px' : '0' }}>
          <div style={{ maxHeight: '150px', overflowY: 'auto', textAlign: 'left' }}>
            <p>{transcript}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainRecPage;
