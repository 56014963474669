import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FrontPage = ({ selectedCategories, setSelectedCategories, navigateToPage }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoriesLocal, setSelectedCategoriesLocal] = useState(selectedCategories); // Initialize with selectedCategories
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    axios.get('/data')
      .then(response => {
        const sortedCategories = response.data.categories.sort((a, b) => a.localeCompare(b));
        setCategories(sortedCategories);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  useEffect(() => {
    // Update the selected categories in the parent state whenever local state changes
    setSelectedCategories(selectedCategoriesLocal);
  }, [selectedCategoriesLocal, setSelectedCategories]);

  const handleCheckboxChange = (category) => {
    if (selectedCategoriesLocal.includes(category)) {
      setSelectedCategoriesLocal(selectedCategoriesLocal.filter(cat => cat !== category));
    } else {
      setSelectedCategoriesLocal([...selectedCategoriesLocal, category]);
    }
  };

  const handleShowSelected = () => {
    if (selectedCategoriesLocal.length > 0) {
      navigateToPage('mainpage', null, selectedCategoriesLocal);
    }
  };

  const filteredCategories = categories.filter(category =>
    category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <header className="App-header">
        <div className="App-logo" /> 
        <h1>Welcome to the Mind Map</h1>
      </header>
      <div className='App-select'>
        <input className="search"
          type="text"
          placeholder="Search categories..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ padding: '5px' }}
        />
        <button
          className="btn"
          onClick={handleShowSelected}
          disabled={selectedCategoriesLocal.length === 0}
        >
          Show selected topics
        </button>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', gap: '10px', maxHeight: '335px', overflowY: 'auto', marginTop: '15px'}}>
        {filteredCategories.map(category => (
          <div key={category} className="category-item">
            <input
              type="checkbox"
              id={category}
              checked={selectedCategoriesLocal.includes(category)}
              onChange={() => handleCheckboxChange(category)}
            />
            <label htmlFor={category}>{category}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrontPage;
